import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Workshop } from '../api-clients/WorkshopLocatorApi';
import { RootState } from '../store/reducers';
import { setWorkshopPreviewed } from '../store/reducers/workshop-previewed';
import BrandLogo from './BrandLogo';
import Distance from './Distance';
import WorkshopClipboardButton from './WorkshopClipboardButton';
import WorkshopMarker from './WorkshopMarker';
import { useTranslation } from 'react-i18next';
import Duration from './Duration';
import { WorkshopLink } from './WorkshopLink';
import CustomerServiceItem from './CustomerServiceItem';
import ElementTooltip from './ElementTooltip';
import IdsIcon from './IdsIcon';

//A single workshop
const WorkshopResult = ({ workshop }: InputProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const workshopPreviewed = useSelector((state: RootState) => state.workshopPreviewed);

  function hooverWorkshop(workshop: Workshop | null): void {
    dispatch(setWorkshopPreviewed(workshop));
  }

  const selectedClassName = workshopPreviewed && workshopPreviewed.id === workshop.id ? '-beige-300' : '-beige-100';

  return (
    <div
      className={`if ws-result color background${selectedClassName}`}
      onMouseEnter={(): void => hooverWorkshop(workshop)}
      onMouseLeave={(): void => hooverWorkshop(null)}
    >
      <div className='ws-left'>
        <div className='ws-marker'>
          <WorkshopMarker index={workshop.order} />{' '}
        </div>
        <BrandLogo workshop={workshop} />
      </div>
      <div className='if flex-column gap-12'>
        <div className='if flex-row justify-content-space-between'>
          <span className='if typography ui medium weight-bold'>{workshop.name}</span>
          <div className='if flex-row gap-8'>
            <WorkshopLink workshop={workshop} />
            <WorkshopClipboardButton workshop={workshop} />
          </div>
        </div>

        <div className='if flex-row'>
          <div
            id='address'
            className='if flex-column flex-grow if typography ui medium weight-medium gap-4'
            style={{ flexBasis: '40%' }}
          >
            <span>{workshop.address?.street}</span>
            <span>{`${workshop.address?.postalCode} ${workshop.address?.city}`}</span>
            <span>{workshop.phone}</span>
          </div>
          <div className='if flex-column flex-center flex-grow gap-4' style={{ flexBasis: '20%' }}>
            <Distance distance={workshop.distance} />
            <Duration duration={workshop.duration} />
            {workshop.ifCertifiedWorkshop && (
              <ElementTooltip text={t('ifCertifiedWorkshop')}>
                <IdsIcon group='ui' icon='cockade' />
              </ElementTooltip>
            )}
          </div>
          <div className='if flex-row flex-wrap align-items-flex-end gap-8' style={{ flexBasis: '20%' }}>
            {workshop.customerServices?.map((customerService) => (
              <CustomerServiceItem
                workshopId={workshop.id}
                customerService={customerService}
                key={customerService!.serviceType}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default WorkshopResult;

type InputProps = {
  workshop: Workshop;
};
