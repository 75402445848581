import React from 'react';
import { useTranslation } from 'react-i18next';
import { Workshop } from '../api-clients/WorkshopLocatorApi';
import ElementTooltip from './ElementTooltip';
import IdsIcon from './IdsIcon';
import FadeOutPopover from './FadeOutPopover';

const WorkshopClipboardButton = ({ workshop }: InputProps): JSX.Element | null => {
  const { t } = useTranslation();
  function CopyWorkshopInfoToClipboard(): string {
    let copy = (workshop.name || '') + '\n';
    copy += (workshop.address?.street || '') + '\n';
    copy += (workshop.address?.postalCode || '') + ' ';
    copy += (workshop.address?.city || '') + '\n';
    copy += t('workshopInformation.phoneNumber') + '\xa0' + (workshop.phone || '') + '\n';
    return copy;
  }

  const copyOnClick = (): void => {
    navigator.clipboard.writeText(CopyWorkshopInfoToClipboard());
  };

  if (!workshop) {
    return null;
  }

  return (
    <ElementTooltip text={t('copyContact')}>
      <FadeOutPopover content={`${t('customerService.copied')}!`}>
        <span onClick={copyOnClick} style={{ cursor: 'pointer' }}>
          <IdsIcon group='ui' icon='copy' color='blue' />
        </span>
      </FadeOutPopover>
    </ElementTooltip>
  );
};

export default WorkshopClipboardButton;

type InputProps = {
  workshop: Workshop;
};
