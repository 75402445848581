import { Workshop } from '../../api-clients/WorkshopLocatorApi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const workshopPreviewedSlice = createSlice({
  name: 'workshopPreviewed',
  initialState: null as Workshop | null,
  reducers: {
    setWorkshopPreviewed(state, action: PayloadAction<Workshop | null>): Workshop | null {
      return action.payload;
    },
  },
});

export const { setWorkshopPreviewed } = workshopPreviewedSlice.actions;

export default workshopPreviewedSlice.reducer;
