import React from 'react';
import ElementTooltip from './ElementTooltip';
import IdsIcon from './IdsIcon';
import FadeOutPopover from './FadeOutPopover';
import { useTranslation } from 'react-i18next';

type InputProps = {
  email: string | undefined;
  popoverTitle: string;
};

const WorkshopLetterEmailCopyIcon = ({ email, popoverTitle }: InputProps): JSX.Element => {
  const { t } = useTranslation();

  const copyEmailOnClick = (): void => {
    if (email) {
      navigator.clipboard.writeText(email);
    }
  };

  return (
    <ElementTooltip text={popoverTitle}>
      <FadeOutPopover content={`${t('customerService.copied')}!`}>
        <a onClick={copyEmailOnClick} style={{ cursor: 'pointer' }}>
          <IdsIcon icon='email' group='ui' color='blue' />
        </a>
      </FadeOutPopover>
    </ElementTooltip>
  );
};

export default WorkshopLetterEmailCopyIcon;
