import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en.json';
import fi from './locales/fi.json';
import no from './locales/no.json';
import sv from './locales/sv.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    resources: {
      en: { translation: en },
      fi: { translation: fi },
      no: { translation: no },
      sv: { translation: sv },
    },
    debug: false,
    interpolation: { escapeValue: false },
    detection: {
      order: ['localStorage', 'cookie', 'queryString'],
      cache: ['localStorage', 'cookie'],
    },
    supportedLngs: ['en', 'fi', 'no', 'sv'],
  });

export default i18n;
