import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './reducers';

// Store creator
// uses the combined reducers
const store = configureStore({
  reducer: rootReducer,
});

// Type definitions for TypeScript
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action>;

export default store;
