import React from 'react';
import { loginUser } from '../util/auth';
import { useTranslation } from 'react-i18next';

const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const loginClick = (): void => {
    loginUser();
  };

  return (
    <div>
      <button className='if button filled' onClick={loginClick}>
        {t('loginButton')}
      </button>
    </div>
  );
};

export default Login;
