import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiMessage, ValidationProblemDetails } from '../api-clients/WorkshopLocatorApi';

const isApiError = (object: any): boolean => {
  if ((object as ApiMessage).isError) {
    return true;
  }

  return false;
};

const isValidationProblemDetails = (object: any): boolean => {
  if ((object.error as ValidationProblemDetails).errors) {
    return true;
  }

  return false;
};

interface QueryError {
  title: string;
  messages?: string[] | null;
}

const ValidationMessages: Record<string, string> = {
  RegistrationNumber: 'errorDisplay.registrationNumberValidation',
  Latitude: 'errorDisplay.locationValidation',
  Longitude: 'errorDisplay.locationValidation',
};

const ErrorDisplay = (error: any): JSX.Element => {
  const { t } = useTranslation();
  const [originalError] = useState(error);
  const [queryError, setQueryError] = useState<QueryError>();

  useEffect(() => {
    let title = originalError.message || t('defaultMessage');
    let messages = null;

    if (isValidationProblemDetails(originalError)) {
      messages = Object.entries(originalError.error.errors || []).map((validationError): string =>
        t(ValidationMessages[validationError[0].toString()])
      );

      title = originalError.error.title || title;
    } else if (isApiError(originalError)) {
      console.log(originalError.message);
    } else if (originalError.error && originalError.error.message) {
      title = originalError.error.message;
    }

    setQueryError({
      title: title,
      messages: messages,
    });
  }, [originalError]);

  return (
    <>
      {queryError && (
        <div className='ws-error'>
          <div className='if notification error'>
            <span className='if heading smallest'>{queryError.title}</span>
            <p>
              {queryError.messages &&
                queryError.messages.map((message, keyNumber) => <span key={keyNumber}>{message}</span>)}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ErrorDisplay;
