import React from 'react';
import { useField } from 'formik';
import { TextInputFieldFormik } from './TextInputField';
import { useTranslation } from 'react-i18next';

export function RegistrationNumberOrManufacturerField(props: {
  setUrlQueryParameter(inputField: { target: HTMLInputElement }): void;
}): JSX.Element {
  const { t } = useTranslation();
  const [field] = useField<string>('registrationNumber');
  let translationKey = 'vehicleInformation.registrationNumber';
  if (field.value?.startsWith('#')) {
    translationKey = 'vehicleInformation.manufacturer';
  }
  return (
    <TextInputFieldFormik
      label={t(translationKey)}
      name='registrationNumber'
      type='text'
      onBlur={props.setUrlQueryParameter}
    />
  );
}
