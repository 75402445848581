import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setFromShowWorkshops } from '../../store/reducers/query-location';
import { setQueryStringValue } from '../../util/queryString';
import UrlService from '../../util/UrlService';
import './ButtonLoader.scss';

type InputProps = {
  children: string;
  disabled: boolean;
  loading: boolean;
};

const urlService = new UrlService();
const isLoaded = (): boolean => {
  const moreLoaded = urlService.getShowMore() ?? false;
  return moreLoaded;
};

export const ButtonLoader = ({ children, loading, disabled }: InputProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleNewSearchClick = (previousLoad: boolean): void => {
    const value = previousLoad ? '' : 'false';
    setQueryStringValue('showMore', value);
    dispatch(setFromShowWorkshops(false));
  };
  return (
    <button
      type='submit'
      disabled={disabled}
      className='if button filled'
      onClick={(): void => handleNewSearchClick(isLoaded())}
    >
      {loading ? (
        <>
          <svg className='if loader dots horizontal white' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'>
            <g className='if svg g'>
              <circle className='if svg circle' fill='#faf9f7' cx='8' cy='32' r='6' />
              <circle className='if svg circle' fill='#faf9f7' cx='32' cy='32' r='6' />
              <circle className='if svg circle' fill='#faf9f7' cx='56' cy='32' r='6' />
            </g>
          </svg>
          <span>{t('searching')}</span>
        </>
      ) : (
        <span>{children}</span>
      )}
    </button>
  );
};
