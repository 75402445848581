import { VehicleType, ClaimType, InsuranceType } from '../api-clients/WorkshopLocatorApi';
import { LatLng } from '../models/LatLng';

enum Param {
  RegistrationNumber = 'registrationnumber',
  VehicleType = 'vehicletype',
  ClaimType = 'claimtype',
  CountryCode = 'countrycode',
  Address = 'address',
  LatLng = 'latlng',
  InsuranceType = 'insurancetype',
  ShowMore = 'showmore',
}

export default class UrlService {
  urlquery = new URLSearchParams(window.location.search.toLowerCase());

  getRegistrationNumber = (): string => {
    return this.urlquery.get(Param.RegistrationNumber) || '';
  };

  getVehicleType = (): VehicleType | undefined => {
    const param = this.urlquery.get(Param.VehicleType);
    if (param) {
      return Object.values(VehicleType).find((v) => v.toLocaleLowerCase() === param.toLowerCase());
    }
    return undefined;
  };

  getClaimsType = (): ClaimType | undefined => {
    const param = this.urlquery.get(Param.ClaimType);
    if (param) {
      return Object.values(ClaimType).find((v) => v.toLocaleLowerCase() === param.toLowerCase());
    }
    return undefined;
  };

  getInsuranceType = (): InsuranceType | undefined => {
    const param = this.urlquery.get(Param.InsuranceType);
    if (param) {
      return Object.values(InsuranceType).find((v) => v.toLocaleLowerCase() === param.toLowerCase());
    }

    return undefined;
  };

  getCountryCode = (): string => {
    return this.urlquery.get(Param.CountryCode)?.toUpperCase() || '';
  };

  getAddress = (): string => {
    return this.urlquery.get(Param.Address) || '';
  };

  getLatLng = (): LatLng | undefined => {
    const param = this.urlquery.get(Param.LatLng);

    if (param) {
      const split = param.split(',');
      if (split.length === 2) {
        const latlng: LatLng = {
          lat: Number(split[0]),
          lng: Number(split[1]),
        };
        return latlng;
      }
      return undefined;
    }
  };

  getShowMore = (): boolean | undefined => {
    const param = this.urlquery.get(Param.ShowMore);
    if (param) {
      return param.toLowerCase() !== 'false';
    }
    return undefined;
  };
}
