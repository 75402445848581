import React, { PropsWithChildren, useRef, useState } from 'react';
import './Popover.scss';
import {
  useFloating,
  offset,
  useHover,
  useFocus,
  useRole,
  useInteractions,
  FloatingArrow,
  arrow,
} from '@floating-ui/react';
import { flip, shift } from '@floating-ui/react-dom';

type InputProps = {
  text: string;
};

const ElementTooltip = ({ text, children }: PropsWithChildren<InputProps>): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const arrowRef = useRef(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'top',
    middleware: [offset(10), flip(), shift(), arrow({ element: arrowRef })],
  });

  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const role = useRole(context, { role: 'dialog' });

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, role]);

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()}>
        {children}
      </div>
      {isOpen && (
        <div ref={refs.setFloating} style={floatingStyles} className={'if popover is-open'} {...getFloatingProps()}>
          <div className='if typography paragraph medium inverted'>{text}</div>
          <FloatingArrow ref={arrowRef} context={context} fill='var(--ids-popover-background-color)' />
        </div>
      )}
    </>
  );
};

export default ElementTooltip;
