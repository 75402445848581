import React from 'react';
import { FaMapMarker } from 'react-icons/fa';

const WorkshopMarker = ({ index }: InputProps): JSX.Element => {
  if (!index) {
    return <></>;
  }

  return (
    <svg width='30' height='30'>
      <FaMapMarker size='28' x='0' y='0' width='12' height='10' stroke='blue' strokeWidth='3px' fill='blue' />
      <text color='white' fontSize='12' x='33%' y='50%' textAnchor='center' fill='white'>
        {index}
      </text>
    </svg>
  );
};

export default WorkshopMarker;

type InputProps = {
  index: number | undefined;
};
