import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from '../constants/languages';
import CountryToggler from './CountryToggler';
import { FormikProps } from 'formik';
import Query from '../models/Query';
import './LanguageSelector.scss';

type InputProps = {
  meta: FormikProps<Query>;
};

const LanguageSelector = ({ meta }: InputProps): JSX.Element => {
  const { i18n } = useTranslation();
  const setLanguage = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    const languageCode = event.target.value;
    i18n.changeLanguage(languageCode);
    event.target.blur();
  };

  useEffect(() => {
    //needed for IDS
    document.documentElement.setAttribute('lang', i18n.language);
  }, [i18n.language]);

  return (
    <header className='if minimal-header background-beige-300'>
      <div className='if pl-32' id='language-selector'>
        <select
          id='selected'
          className='if dropdown-select'
          name='selected'
          onChange={(e): void => setLanguage(e)}
          value={i18n.language}
        >
          {languages.map((l) => (
            <option key={l.code} data-cy='selectedLanguage' value={l.code}>
              {l.code.toUpperCase()}
            </option>
          ))}
        </select>
      </div>
      <div className='if pr-32' id='flag-icon'>
        <CountryToggler meta={meta} />
      </div>
    </header>
  );
};

export default LanguageSelector;
