const newline = '%0D%0A';

const finnishLetterBody = `Hei,${newline}
Pyydämme ottamaan yhteyttä asiakkaaseen palvelulupauksen puitteissa. Huomioittehan myös asiakkaalle mahdollisesti korvattavan sijaisauton.${newline}${newline}

Rekisterinumero: ${newline}
Asiakas: ${newline}
Puhelinnumero: ${newline}
Sähköposti: ${newline}
Vahinkotunnus: ${newline}
Korjaamo: ${newline}
Korvataanko asiakkaalle sijaisauto?${newline}
Sijaisauton luokka, Ifin osuus, alv:${newline}
Muuta:${newline}${newline}

Ystävällisin terveisin${newline}${newline}
If Vakuutus${newline}
Korvauspalvelu${newline}${newline}`;

export const workshopLetterSubjects: { [key: string]: string } = {
  FI: 'If Asiakasohjaus',
  NO: 'Verkstedbekreftelse',
  SE: 'Verkstadsbrev',
};

export const workshopLetterBodies: { [key: string]: string } = {
  FI: finnishLetterBody,
};
