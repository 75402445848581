import { finnishClaimTypes, norwegianClaimTypes, swedishClaimTypes } from '../constants/claimsTypes';
import { finnishInsuranceTypes, norwegianInsuranceTypes, swedishInsuranceTypes } from '../constants/insuranceTypes';
import { finnishVehicleTypes, norwegianVehicleTypes, swedishVehicleTypes } from '../constants/vehicleTypes';
import { CountryCode } from '../enums/country-code';
import { TextValue } from '../models/TextValue';

class CountrySpecificDataProvider {
  readonly claimTypes: TextValue[];
  readonly insuranceTypes: TextValue[];
  readonly vehicleTypes: TextValue[];

  constructor(countryCode: string) {
    switch (countryCode.toUpperCase()) {
      case CountryCode.Finland:
        this.claimTypes = finnishClaimTypes;
        this.insuranceTypes = finnishInsuranceTypes;
        this.vehicleTypes = finnishVehicleTypes;
        break;
      case CountryCode.Norway:
        this.claimTypes = norwegianClaimTypes;
        this.insuranceTypes = norwegianInsuranceTypes;
        this.vehicleTypes = norwegianVehicleTypes;
        break;
      default:
        this.claimTypes = swedishClaimTypes;
        this.insuranceTypes = swedishInsuranceTypes;
        this.vehicleTypes = swedishVehicleTypes;
        break;
    }
  }
}

export default CountrySpecificDataProvider;
