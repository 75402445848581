export const findAddressFromUrl = (
  addressQuery: string,
  locationResponseHandler: (
    results: google.maps.places.PlaceResult[],
    status: google.maps.places.PlacesServiceStatus
  ) => void
): void => {
  const placesService = new google.maps.places.PlacesService(document.createElement('div'));
  const placeRequest = {
    query: addressQuery,
    fields: ['formatted_address', 'geometry'],
  };
  placesService.findPlaceFromQuery(placeRequest, locationResponseHandler);
};

export const instantiateAutocomplete = (
  inputRef: HTMLInputElement,
  autocompletePlaceChanged: {
    (): void;
    (this: google.maps.places.Autocomplete, ...args: any[]): void;
  }
): google.maps.places.Autocomplete => {
  const auto = new google.maps.places.Autocomplete(inputRef, {
    //types: ['(cities)'],
  });
  auto.setComponentRestrictions({
    country: ['se', 'fi', 'no'], //Or only to Query countryCode
  });
  auto.setFields(['formatted_address', 'geometry']);
  auto.addListener('place_changed', autocompletePlaceChanged);

  inputRef.addEventListener('keydown', (event: Event) => {
    const keyboardEvent = event as KeyboardEvent;
    if (keyboardEvent.key === 'Enter') {
      event.preventDefault();
    }
  });
  return auto;
};
