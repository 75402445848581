import { ClaimType, InsuranceType, VehicleType } from '../api-clients/WorkshopLocatorApi';
import UrlService from '../util/UrlService';

export default class Query {
  registrationNumber: string;
  vehicleType: VehicleType = VehicleType.Car;
  claimType: ClaimType = ClaimType.Collision;
  insuranceType: InsuranceType = InsuranceType.NonBranded;
  countryCode = 'SE';
  latitude: number | '' = '';
  longitude: number | '' = '';
  showMore: boolean | undefined;

  constructor(urlService: UrlService) {
    const latlng = urlService.getLatLng();
    if (latlng) {
      this.latitude = latlng?.lat;
      this.longitude = latlng?.lng;
    }

    this.registrationNumber = urlService.getRegistrationNumber();
    this.vehicleType = urlService.getVehicleType() || this.vehicleType;
    this.claimType = urlService.getClaimsType() || this.claimType;
    this.insuranceType = urlService.getInsuranceType() || this.insuranceType;
    this.countryCode = urlService.getCountryCode() || this.countryCode;
    this.showMore = urlService.getShowMore();
  }
}
