import userSlice from './user';
import workshopsSlice from './workshops';
import queryLocation from './query-location';
import workshopPreviewedSlice from './workshop-previewed';
import workshopsByServiceTypeSlice from './workshops-by-servicetype';
import serviceTypeSelectedIndexSlice from './servicetype-selected-index';
import { combineReducers } from '@reduxjs/toolkit';

// Reducer creator. Combine the reduce-slices.
const rootReducer = combineReducers({
  user: userSlice,
  workshops: workshopsSlice,
  queryLocation: queryLocation,
  workshopPreviewed: workshopPreviewedSlice,
  workshopsByServiceType: workshopsByServiceTypeSlice,
  serviceTypeSelectedIndex: serviceTypeSelectedIndexSlice,
});

// Define what the rootstate should be
export type RootState = ReturnType<typeof rootReducer>;

// export the rootreducer for the store
export default rootReducer;
