import React, { ReactNode } from 'react';
import './DialogModal.scss';

export const DialogModal = ({ header, content, actions }: InputProps): JSX.Element => {
  return (
    <>
      <div
        className='if backdrop is-open'
        role='dialog'
        aria-labelledby='modal-title'
        aria-describedby='modal-description'
      ></div>
      <div data-speccer className='if modal'>
        <div className='if typography heading small pl-40 pr-24' id='modal-title'>
          {header}
        </div>
        <span className='if axe sr-only' id='modal-description'></span>
        <div className='if content'>{content}</div>
        <div className='if footer'>{actions}</div>
      </div>
    </>
  );
};

type InputProps = {
  header: ReactNode;
  content: ReactNode;
  actions: ReactNode;
};
