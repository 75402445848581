import { UserAgentApplication, AuthenticationParameters, Account } from 'msal';
import { environment } from './environment';

//Azure AD auth
export const msalConfig = new UserAgentApplication({
  auth: {
    authority: environment.authority,
    clientId: environment.clientId,
    redirectUri: environment.redirectUrl,
    validateAuthority: true,
    navigateToLoginRequestUrl: true,
  },

  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
});

const wsApiRequest: AuthenticationParameters = {
  scopes: [environment.authScope],
  authority: environment.authority,
};

const authRequest: AuthenticationParameters = {
  scopes: ['user.read'],
  authority: environment.authority,
};

const requiresInteraction = (errorMessage: string): boolean => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf('consent_required') !== -1 ||
    errorMessage.indexOf('interaction_required') !== -1 ||
    errorMessage.indexOf('login_required') !== -1
  );
};

export function getAccount(): Account {
  return msalConfig.getAccount();
}

export const getAccountState = (): boolean => {
  return !!getAccount();
};

async function getToken(request: AuthenticationParameters): Promise<string> {
  try {
    const silentRequest = await msalConfig.acquireTokenSilent(request);
    return silentRequest.accessToken;
  } catch (error) {
    console.log(error);
    console.log(error.errorCode);
    if (
      requiresInteraction(error) ||
      error.errorCode === 'consent_required' ||
      error.errorCode === 'interaction_required' ||
      error.errorCode === 'login_required'
    ) {
      msalConfig.acquireTokenRedirect(request);
      return '';
    } else {
      throw new Error('Non-interactive error: ' + error.errorCode);
    }
  }
}

export async function getAzureToken(): Promise<string> {
  const request = authRequest;
  return getToken(request);
}

export function loginUser(): void {
  msalConfig.loginRedirect();
}

export async function getApiToken(): Promise<string> {
  const request = wsApiRequest;
  return getToken(request);
}
