import { InsuranceType } from '../api-clients/WorkshopLocatorApi';
import { TextValue } from '../models/TextValue';

export const swedishInsuranceTypes: TextValue[] = [
  new TextValue('insuranceTypes.nonBranded', InsuranceType.NonBranded),
  new TextValue('insuranceTypes.branded', InsuranceType.Branded),
  new TextValue('insuranceTypes.collectorVehicle', InsuranceType.CollectorVehicle),
  new TextValue('insuranceTypes.warranty', InsuranceType.Warranty),
];

export const finnishInsuranceTypes: TextValue[] = [
  new TextValue('insuranceTypes.nonBranded', InsuranceType.NonBranded),
  new TextValue('insuranceTypes.branded', InsuranceType.Branded),
];

export const norwegianInsuranceTypes: TextValue[] = [
  new TextValue('insuranceTypes.nonBranded', InsuranceType.NonBranded),
  new TextValue('insuranceTypes.branded', InsuranceType.Branded),
];
