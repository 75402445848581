import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Workshop } from '../api-clients/WorkshopLocatorApi';
import { defaultLocation } from '../constants/defaultLocations';
import { MapMarkerPosition } from '../models/LatLng';
import { RootState } from '../store/reducers';
import { LocationSource, setFromPlaceResult } from '../store/reducers/query-location';
import { setWorkshopPreviewed } from '../store/reducers/workshop-previewed';
import MapService from '../util/mapService';
import { setQueryStringLatLong, setQueryStringValue } from '../util/queryString';
import WorkshopMap from './WorkshopMap';

const MapContainer = (): JSX.Element | null => {
  const dispatch = useDispatch();

  const workshops = useSelector((state: RootState) => state.workshops);
  const { latLng: queryLocation, countryCode } = useSelector((state: RootState) => state.queryLocation);
  const workshopPreviewed = useSelector((state: RootState) => state.workshopPreviewed);

  const workshopInfoWindow = new google.maps.InfoWindow({ content: '' });
  const [selectedWorkshopInfoWindow] = useState(
    new google.maps.InfoWindow({
      content: '',
    })
  );

  const [workshopMap, setWorkshopMap] = useState<google.maps.Map | undefined>(undefined);

  const [workshopMarkerPositions, setWorkshopMarkerPositions] = useState<(MapMarkerPosition | null)[] | undefined>(
    undefined
  );

  const [queryMarker, setQueryMarker] = useState<google.maps.Marker | undefined>(undefined);

  const [selectedWorkshopMarkerPosition, setSelectedWorkshopMarkerPosition] = useState<MapMarkerPosition | undefined>(
    undefined
  );

  function clickedMapEvent(this: google.maps.Map, mouse: google.maps.MouseEvent): void {
    const latLng = {
      lat: mouse.latLng.lat(),
      lng: mouse.latLng.lng(),
    };
    dispatch(
      setFromPlaceResult({
        latLng,
        source: LocationSource.Map,
        address: '',
        showMore: false,
      })
    );
    setQueryStringLatLong(latLng);
    setQueryStringValue('address', '');
  }

  function clickedWorkshopEvent(this: google.maps.Marker): void {
    const workshop = this.get('workshop');
    if (!workshop) {
      return;
    }

    const info = workshop.name;
    const map = this.getMap();

    if (info && map) {
      dispatch(setWorkshopPreviewed(workshop));
    }
  }

  function setWorkshopMapWithMarker(marker: google.maps.Marker, workshopMap: google.maps.Map): void {
    marker.addListener('click', clickedWorkshopEvent);
    marker.setMap(workshopMap);
    setQueryMarker(marker);
  }

  function hooverWorkshopEvent(this: google.maps.Marker): void {
    const workshop = this.get('workshop');
    const info = workshop.name;
    const map = this.getMap();

    if (info && map) {
      workshopInfoWindow.setContent(info.toString());
      workshopInfoWindow.open(map, this);
    }
  }

  function hooverLeaveWorkshopEvent(this: google.maps.Marker): void {
    const map = this.getMap();

    if (map) {
      workshopInfoWindow.close();
    }
  }

  const setBounds = (map: google.maps.Map, markers: (MapMarkerPosition | null)[]): void => {
    if (!queryMarker || !markers) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    markers.forEach((marker) => {
      if (marker) {
        bounds.extend(marker.latLng);
      }
    });

    const queryPosition = queryMarker.getPosition();
    if (queryPosition) {
      bounds.extend(queryPosition);
    }

    map.fitBounds(bounds);
  };

  const setWorkshops = (workshops: (Workshop | undefined)[], map: google.maps.Map): void => {
    if (workshopMarkerPositions) {
      workshopMarkerPositions.forEach((marker) => {
        if (marker) {
          marker.marker.setMap(null);
        }
      });
    }

    const markers = MapService.createMarkersFromWorkshops(workshops);

    if (markers && markers.length > 0) {
      markers.forEach((marker) => {
        if (marker) {
          marker.marker.setMap(map);
          marker.marker.addListener('click', clickedWorkshopEvent);
          marker.marker.addListener('mouseover', hooverWorkshopEvent);
          marker.marker.addListener('mouseout', hooverLeaveWorkshopEvent);
        }
      });

      setBounds(map, markers);
    }

    setWorkshopMarkerPositions(markers);
    if (queryLocation) {
      queryMarker?.setMap(null);
      setWorkshopMapWithMarker(
        new google.maps.Marker({
          position: queryLocation,
        }),
        map
      );
    }
  };

  // create workshop markers when we have workshops
  useEffect(() => {
    if (!workshopMap || !workshops) {
      return;
    }

    setWorkshops(workshops, workshopMap);
  }, [workshops]);

  useEffect(() => {
    if (!workshopMap) {
      return;
    }

    workshopMap.addListener('click', clickedMapEvent);
  }, [workshopMap]);

  // create workshop markers when we have workshops
  useEffect(() => {
    if (queryLocation && workshopMap) {
      if (queryMarker) {
        queryMarker.setMap(null);
      }

      workshopMap.setCenter(queryLocation);
      const marker = new google.maps.Marker({
        position: queryLocation,
      });

      setWorkshopMapWithMarker(marker, workshopMap);
    } else if (workshopMap && countryCode) {
      if (queryMarker) {
        queryMarker.setMap(null);
      }

      workshopMap.setCenter(defaultLocation[countryCode]);
      const marker = new google.maps.Marker({
        position: defaultLocation[countryCode],
      });

      setWorkshopMapWithMarker(marker, workshopMap);
    }
  }, [queryLocation, countryCode]);

  useEffect(() => {
    selectedWorkshopInfoWindow.close();

    if (!workshopMap || !workshopPreviewed || !workshopPreviewed.latitude || !workshopPreviewed.longitude) {
      return;
    }

    if (selectedWorkshopMarkerPosition) {
      selectedWorkshopMarkerPosition.marker.setIcon(MapService.getDefaultWorkshopIcon());
    }

    const newMarkerPosition = workshopMarkerPositions?.find((m) => m?.workshopCode === workshopPreviewed.code);

    if (!newMarkerPosition) {
      return;
    }

    newMarkerPosition.marker.setIcon(MapService.getSelectedWorkshopIcon());

    const info = workshopPreviewed.name;
    const map = newMarkerPosition.marker.getMap();

    if (info && map) {
      selectedWorkshopInfoWindow.setContent(info.toString());
      selectedWorkshopInfoWindow.open(map, newMarkerPosition.marker);
      setSelectedWorkshopMarkerPosition(newMarkerPosition);
    }
  }, [workshopPreviewed]);

  return (
    <div className='map-container'>
      <WorkshopMap setMap={setWorkshopMap} />
    </div>
  );
};

export default MapContainer;
