import React, { useEffect } from 'react';
import './Toast.scss';

export enum ToastType {
  Success,
  Error,
  Warning,
  Info,
}

export default function Toast({
  message,
  type,
  duration,
  onClose,
}: {
  message: string;
  type: ToastType;
  duration: number;
  onClose: () => void;
}) {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  return <div className={`if toast ${ToastType[type].toLowerCase()} bottom-left`}>{message}</div>;
}
