import { User } from '../../models/User';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getAzureToken, getAccount } from '../../util/auth';
import { environment } from '../../util/environment';
import { AppThunk } from '..';
import { get } from '../../util/FetchWrappers';

const initialState: User | null = null;

// Create a "slice" to get actions/reducers for a object
const userSlice = createSlice({
  name: 'user',
  initialState: initialState as User | null,
  reducers: {
    setUser(state, action: PayloadAction<User>): User {
      return action.payload;
    },
  },
});

// export of the action not needed since it's only used by "fetchUser"
const { setUser } = userSlice.actions;

// Export the reducer to be used in the rootReducer creation
export default userSlice.reducer;

// Async function with thunk.
// Thunk is middleware that gives the store asynchronous functionality.
// Works like an action. Called with "dispatch"
export const fetchUser = (): AppThunk => async (dispatch): Promise<void> => {
  const token = await getAzureToken();
  const account = getAccount();

  if (account && token) {
    const user = await get<User>(environment.clientUrl, token);
    dispatch(setUser(user));
  }
};
