import { Workshop } from '../api-clients/WorkshopLocatorApi';
import { defaultLocation } from '../constants/defaultLocations';
import { LatLng, MapMarkerPosition } from '../models/LatLng';

export default class MapService {
  static getMap = (ref: HTMLInputElement, countryCode: string): google.maps.Map<HTMLInputElement> => {
    return new google.maps.Map(ref, {
      center: defaultLocation[countryCode],
      zoom: 8,
      styles: [
        {
          featureType: 'administrative',
          elementType: 'geometry',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'poi',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
        {
          featureType: 'road',
          elementType: 'labels.icon',
          stylers: [
            {
              visibility: 'off',
            },
          ],
        },
      ],
    });
  };

  static createMarkersFromWorkshops = (workshops: (Workshop | undefined)[]): (MapMarkerPosition | null)[] => {
    if (!workshops) {
      return [];
    }

    return workshops
      .filter((workshop) => workshop && !!workshop.latitude && !!workshop.longitude)
      .map((workshop) => {
        if (workshop) {
          return MapService.createMarkerPosition(workshop);
        }

        return null;
      });
  };

  static createMarkerPosition = (workshop: Workshop): MapMarkerPosition => {
    const position: LatLng = {
      lat: workshop.latitude as number,
      lng: workshop.longitude as number,
    };

    const marker = new google.maps.Marker({
      position: position,
      label: {
        text: String(workshop.order),
        color: 'white',
        fontWeight: 'bold',
      },
      icon: MapService.getDefaultWorkshopIcon(),
    });
    marker.set('workshop', workshop);

    return new MapMarkerPosition(marker, position, workshop.code);
  };

  static getSelectedWorkshopIcon = (): google.maps.ReadonlyIcon => {
    return {
      url: 'location_selected-24px.svg',
      scaledSize: new google.maps.Size(48, 48),
    };
  };

  static getDefaultWorkshopIcon = (): google.maps.ReadonlyIcon => {
    return {
      url: 'location_on-24px.svg',
      scaledSize: new google.maps.Size(48, 48),
    };
  };
}
