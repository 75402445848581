export class ApiError extends Error {
  status: number;
  message: string;
  details?: string;
  ok: boolean;
  constructor(res: Response, details?: string) {
    super();
    this.status = res.status;
    this.message = res.statusText;
    this.ok = res.ok;
    this.details = details;
  }
}
