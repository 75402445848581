import qs from 'query-string';
import { LatLng } from '../models/LatLng';

export const setQueryStringWithoutPageReload = (qsValue = ''): void => {
  let pathname = window.location.pathname;

  if (pathname === '/index.html') pathname = '/';

  const newurl = window.location.protocol + '//' + window.location.host + pathname + qsValue;

  window.history.pushState({ path: newurl }, '', newurl);
};

export const setQueryStringValue = (key: string, value: string, queryString = window.location.search): void => {
  const values = qs.parse(queryString);
  let newQsValue: string;
  if (value !== '') {
    newQsValue = qs.stringify({ ...values, [key]: value });
  } else {
    delete values[key];
    newQsValue = qs.stringify(values);
  }
  newQsValue = newQsValue.replace(/%2C/g, ',');
  setQueryStringWithoutPageReload(`?${newQsValue}`);
};

export const setQueryStringLatLong = ({ lat, lng }: LatLng): void => {
  //http://wiki.gis.com/wiki/index.php/Decimal_degrees
  const roundTo10cm = (num: number): number => Math.round((num + Number.EPSILON) * 1000000) / 1000000;
  setQueryStringValue('latLng', `${roundTo10cm(lat)},${roundTo10cm(lng)}`);
};

export const resetQueryString = (countryCode: string): void => {
  if (countryCode) {
    setQueryStringWithoutPageReload(`?countryCode=${countryCode}`);
  } else {
    setQueryStringWithoutPageReload();
  }
};
