import React, { PropsWithChildren, useState } from 'react';
import { offset, useFloating, useInteractions, useTransitionStyles } from '@floating-ui/react';

interface FadeOutPopoverProps {
  content: string;
}

const FadeOutPopover: React.FC<PropsWithChildren<FadeOutPopoverProps>> = ({ children, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: 'right',
    open: isOpen,
    middleware: [offset(10)],
  });

  const { isMounted, styles } = useTransitionStyles(context);

  const { getReferenceProps, getFloatingProps } = useInteractions();

  function handleClick() {
    if (!isOpen) {
      setIsOpen(true);
      setTimeout(() => setIsOpen(false), 2000);
    }
  }

  return (
    <>
      <div ref={refs.setReference} {...getReferenceProps()} onClick={handleClick}>
        {children}
      </div>
      {isMounted && (
        <div
          ref={refs.setFloating}
          {...getFloatingProps()}
          style={{ ...styles, ...floatingStyles }}
          className={'if toast success'}
        >
          {content}
        </div>
      )}
    </>
  );
};

export default FadeOutPopover;
