import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkshopServiceType } from '../api-clients/WorkshopLocatorApi';
import './WorkshopResultTab.scss';

type InputProps = {
  index: number;
  selectedIndex: number;
  handleTabClick: (index: number) => void;
  workshopServiceType: WorkshopServiceType | undefined;
};

const WorkshopResultTab = ({
  index,
  selectedIndex,
  handleTabClick,
  workshopServiceType,
}: InputProps): JSX.Element | null => {
  const { t } = useTranslation();

  if (!workshopServiceType) {
    return null;
  }

  return (
    <li className='if' role='presentation'>
      <a
        href='#'
        className='if ws-service-type-tab'
        role='tab'
        {...(index === selectedIndex ? { 'aria-selected': 'true' } : {})}
        onClick={(): void => handleTabClick(index)}
      >
        {t(`workshopServiceType.${workshopServiceType}`)}
      </a>
    </li>
  );
};

export default WorkshopResultTab;
