import { useEffect } from 'react';

const useClickOutsideEffect = (handleClickOutside: (event: MouseEvent) => void): void => {
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
};

export default useClickOutsideEffect;
