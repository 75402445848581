import { ClaimType } from '../api-clients/WorkshopLocatorApi';
import { TextValue } from '../models/TextValue';

export const swedishClaimTypes: TextValue[] = [
  new TextValue('claimTypes.collision', ClaimType.Collision),
  new TextValue('claimTypes.fire', ClaimType.Fire),
  new TextValue('claimTypes.glass', ClaimType.Glass),
  new TextValue('claimTypes.interior', ClaimType.Interior),
  new TextValue('claimTypes.keys', ClaimType.Keys),
  new TextValue('claimTypes.machinery', ClaimType.Machinery),
  new TextValue('claimTypes.notCovered', ClaimType.NotCovered),
  new TextValue('claimTypes.parkedVehicle', ClaimType.ParkedVehicle),
  new TextValue('claimTypes.rescue', ClaimType.Rescue),
  new TextValue('claimTypes.theft', ClaimType.Theft),
  new TextValue('claimTypes.water', ClaimType.Water),
  new TextValue('claimTypes.wrongFuel', ClaimType.WrongFuel),
  new TextValue('claimTypes.vandalism', ClaimType.Vandalism),
];

export const finnishClaimTypes: TextValue[] = [
  new TextValue('claimTypes.collision', ClaimType.Collision),
  new TextValue('claimTypes.fire', ClaimType.Fire),
  new TextValue('claimTypes.glass', ClaimType.Glass),
  new TextValue('claimTypes.keys', ClaimType.Keys),
  new TextValue('claimTypes.machinery', ClaimType.Machinery),
  new TextValue('claimTypes.parkedVehicle', ClaimType.ParkedVehicle),
  new TextValue('claimTypes.theft', ClaimType.Theft),
  new TextValue('claimTypes.vandalism', ClaimType.Vandalism),
];

export const norwegianClaimTypes: TextValue[] = [
  new TextValue('claimTypes.collision', ClaimType.Collision),
  new TextValue('claimTypes.fire', ClaimType.Fire),
  new TextValue('claimTypes.glass', ClaimType.Glass),
  new TextValue('claimTypes.interior', ClaimType.Interior),
  new TextValue('claimTypes.keys', ClaimType.Keys),
  new TextValue('claimTypes.machinery', ClaimType.Machinery),
  new TextValue('claimTypes.parkedVehicle', ClaimType.ParkedVehicle),
  new TextValue('claimTypes.theft', ClaimType.Theft),
  new TextValue('claimTypes.wrongFuel', ClaimType.WrongFuel),
  new TextValue('claimTypes.vandalism', ClaimType.Vandalism),
];
