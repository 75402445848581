import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { setQueryStringValue } from '../util/queryString';
import UrlService from '../util/UrlService';
import ErrorDisplay from './ErrorDisplay';
import WorkshopResult from './WorkshopResult';
import WorkshopResultTabList from './WorkshopResultTabList';
import { setWorkshopsByServiceType } from '../store/reducers/workshops-by-servicetype';
import workshopsLocatorService from '../util/WorkshopsLocatorService';
import Query from '../models/Query';

const WorkshopResults = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loadingMore, setLoadingMore] = useState(false);

  const workshopsByServiceTypes = useSelector((state: RootState) => state.workshopsByServiceType);
  const workshops = useSelector((state: RootState) => state.workshops);
  const allAreLoaded = () => new UrlService().getShowMore() ?? false;
  async function handleReloadClick() {
    try {
      setLoadingMore(true);
      const value = allAreLoaded() ? '' : 'true';
      setQueryStringValue('showMore', value);
      const data = await workshopsLocatorService.search(new Query(new UrlService()));
      dispatch(setWorkshopsByServiceType(data));
      setLoadingMore(false);
    } catch (error) {
      setLoadingMore(false);
    }
  }

  const btnReloadText = allAreLoaded() ? t('workshopResults.showLess') : t('workshopResults.showMore');

  return (
    <>
      {workshopsByServiceTypes.length > 0 && (
        <WorkshopResultTabList workshopsByServiceTypes={workshopsByServiceTypes} />
      )}

      <div className='if ws-result-list'>
        {workshops && workshops.length > 0
          ? workshops.map((workshop) => workshop && <WorkshopResult workshop={workshop} key={workshop.id} />)
          : workshopsByServiceTypes.length > 0 && (
              <ErrorDisplay error={new Error(t('errorDisplay.noWorkshopsFound'))} />
            )}

        {workshops && workshops.length > 0 && (
          <div className='if form-actions'>
            <button className='if button filled' disabled={loadingMore} onClick={handleReloadClick}>
              {loadingMore ? <span className='if loader dots small' /> : btnReloadText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default WorkshopResults;
