import { AuthClient, CreateInspectionRequest, PhotoInspectionApiClient } from '../api-clients/PhotoInspectionApiClient';
import { getApiToken } from './auth';
import { environment } from './environment';

const PhotoInspectionService = {
  async newInspection(body: CreateInspectionRequest) {
    const token = await getApiToken();
    const authClient = new AuthClient(token);
    const client = new PhotoInspectionApiClient(authClient, environment.phinApiUrl);

    return client.createInspection(body);
  },
};

export default PhotoInspectionService;
