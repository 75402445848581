import { FormikProps } from 'formik';
import React from 'react';
import Query from '../../models/Query';
import { LocationFinder } from '../LocationFinder';
import './LocationInputField.scss';

export const LocationInputField = ({ meta }: InputProps): JSX.Element => {
  const latLngError = meta.errors.latitude || meta.errors.longitude;

  return (
    <div className='if form-field'>
      <div className='if form-group mb-12'>
        <LocationFinder meta={meta} inputClasses={latLngError ? 'is-invalid' : ''} />
        <div className='hidden-fields'>
          <input name='longitude' type='hidden' value={meta.values.longitude} />
          <input name='latitude' type='hidden' value={meta.values.latitude} />
        </div>
        {latLngError ? <div className='if input-error'>{latLngError}</div> : null}
      </div>
    </div>
  );
};

type InputProps = {
  meta: FormikProps<Query>;
};
