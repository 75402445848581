import React from 'react';

const Distance = ({ distance }: InputProps): JSX.Element => {
  if (!distance) {
    return <></>;
  }

  return (
    <span className='if typography ui medium weight-bold'>
      {(distance / 1000).toLocaleString('sv-SE', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      })}{' '}
      km
    </span>
  );
};

export default Distance;

type InputProps = {
  distance?: number;
};
