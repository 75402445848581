import { Workshop } from '../../api-clients/WorkshopLocatorApi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const workshopsSlice = createSlice({
  name: 'workshops',
  initialState: [] as (Workshop | undefined)[],
  reducers: {
    setWorkshops(state, action: PayloadAction<(Workshop | undefined)[]>): (Workshop | undefined)[] {
      return action.payload;
    },
  },
});

export const { setWorkshops } = workshopsSlice.actions;

export default workshopsSlice.reducer;
