import React, { useState } from 'react';
import Login from './components/Login';
import WorkshopResults from './components/WorkshopResults';
import WorkshopSearchFormik from './components/WorkshopSearchFormik';
import { getAccount, getAccountState } from './util/auth';
import { DialogModal } from './components/DialogModal';
import MapContainer from './components/MapContainer';
import UpdateMe from './components/UpdateMe';
import './i18n';
import { useTranslation } from 'react-i18next';
import { RootState } from './store/reducers';
import { useSelector } from 'react-redux';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { environment } from './util/environment';
import { IconSprites } from '@ids/react-icon';

// The searchform is using WorkshopSearchFormik. The file WorkshopSearch was the first version, before adding Formik.
const App = (): JSX.Element => {
  const { t } = useTranslation();
  // React Hook useState, state and update method for non-class components
  const [hasAccount] = useState<boolean>(getAccountState());
  const { countryCode } = useSelector((state: RootState) => state.queryLocation);
  // Inline Logical OR expression to show modal if "account" is false
  return (
    <>
      <IconSprites></IconSprites>
      <UpdateMe />
      {!hasAccount && (
        <DialogModal header={<h2>{t('loginTitle')}</h2>} content={t('loginInstruction')} actions={<Login />} />
      )}
      <main className='if main background-beige-300'>
        <div className='main-content'>
          <div className='form-container'>
            <div className='if ws-search background-beige-500'>
              <WorkshopSearchFormik />
            </div>
            <WorkshopResults />
          </div>
          {countryCode && <MapContainer />}
        </div>
      </main>
    </>
  );
};

const account = getAccount();

const user = account
  ? {
      key: account.userName,
    }
  : undefined;
console.debug({ user });

export default withLDProvider({
  clientSideID: environment.launchDarklyID,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  user,
})(App);
