import React from 'react';
import { FieldAttributes, useField } from 'formik';

export const OptionInputFieldFormik = ({ ...props }: FieldAttributes<any>): JSX.Element => {
  const [field, meta] = useField(props.name);
  return (
    <div className='if form-group'>
      <label className='if' htmlFor={props.name}>
        {props.label}
      </label>
      <div className='if dropdown'>
        <select
          {...field}
          {...props}
          className={`if dropdown-select ${meta.touched && meta.error ? 'is-invalid' : ''}`}
        ></select>
      </div>
      {meta.touched && meta.error ? <div className='if input-error'>{meta.error}</div> : null}
    </div>
  );
};
