import { VehicleType } from '../api-clients/WorkshopLocatorApi';
import { TextValue } from '../models/TextValue';

export const swedishVehicleTypes: TextValue[] = [
  new TextValue('vehicleTypes.car', VehicleType.Car),
  new TextValue('vehicleTypes.atv', VehicleType.Atv),
  new TextValue('vehicleTypes.caravan', VehicleType.Caravan),
  new TextValue('vehicleTypes.euMoped', VehicleType.EuMoped),
  new TextValue('vehicleTypes.lightLorry', VehicleType.LightLorry),
  new TextValue('vehicleTypes.mobileHome', VehicleType.MobileHome),
  new TextValue('vehicleTypes.moped', VehicleType.Moped),
  new TextValue('vehicleTypes.mopedCar', VehicleType.Mopedcar),
  new TextValue('vehicleTypes.motorcycle', VehicleType.Motorcycle),
  new TextValue('vehicleTypes.trailer', VehicleType.Trailer),
  new TextValue('vehicleTypes.snowmobile', VehicleType.Snowmobile),
];

export const finnishVehicleTypes: TextValue[] = [
  new TextValue('vehicleTypes.car', VehicleType.Car),
  new TextValue('vehicleTypes.atv', VehicleType.Atv),
  new TextValue('vehicleTypes.caravan', VehicleType.Caravan),
  new TextValue('vehicleTypes.euMoped', VehicleType.EuMoped),
  new TextValue('vehicleTypes.lightLorry', VehicleType.LightLorry),
  new TextValue('vehicleTypes.mobileHome', VehicleType.MobileHome),
  new TextValue('vehicleTypes.mopedCar', VehicleType.Mopedcar),
  new TextValue('vehicleTypes.motorcycle', VehicleType.Motorcycle),
  new TextValue('vehicleTypes.snowmobile', VehicleType.Snowmobile),
];

export const norwegianVehicleTypes: TextValue[] = [
  new TextValue('vehicleTypes.car', VehicleType.Car),
  new TextValue('vehicleTypes.atv', VehicleType.Atv),
  new TextValue('vehicleTypes.caravan', VehicleType.Caravan),
  new TextValue('vehicleTypes.lightLorry', VehicleType.LightLorry),
  new TextValue('vehicleTypes.mobileHome', VehicleType.MobileHome),
  new TextValue('vehicleTypes.moped', VehicleType.Moped),
  new TextValue('vehicleTypes.motorcycle', VehicleType.Motorcycle),
  new TextValue('vehicleTypes.snowmobile', VehicleType.Snowmobile),
  new TextValue('vehicleTypes.trailer', VehicleType.Trailer),
];
