import React, { useState } from 'react';
import { Workshop } from '../api-clients/WorkshopLocatorApi';
import { useTranslation } from 'react-i18next';
import ElementTooltip from './ElementTooltip';

const BrandLogo = ({ workshop }: InputProps): JSX.Element => {
  const { t } = useTranslation();

  const brandFile = workshop?.manufacturer?.name?.toLowerCase();

  const brandPath = `/images/brands/${brandFile}.svg`;
  const brandText = t('authorizedForBrand') + ' ' + workshop?.manufacturer?.name;

  const [url, setUrl] = useState(brandPath);

  function onError(): void {
    const defaultUrl = '/images/brands/default.svg';

    if (url !== defaultUrl) {
      setUrl(defaultUrl);
    }
  }

  if (!workshop || !workshop.manufacturer) {
    return <></>;
  }

  return (
    <ElementTooltip text={brandText}>
      <img className='brand-logo' src={url} alt={brandText} onError={onError} />
    </ElementTooltip>
  );
};

export default BrandLogo;

type InputProps = {
  workshop: Workshop;
};
