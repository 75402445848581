export const environment = {
  wslApiUrl: process.env.REACT_APP_WSL_API_URL!,
  wsUiUrl: process.env.REACT_APP_WS_UI_URL!,
  phinApiUrl: process.env.REACT_APP_PHIN_API_URL!,
  launchDarklyID: process.env.REACT_APP_LAUNCHDARKLY_ID!,
  authScope: process.env.REACT_APP_AUTH_SCOPE!,
  authority: process.env.REACT_APP_AUTHORITY!,
  clientId: process.env.REACT_APP_CLIENT_ID!,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL!,
  clientUrl: process.env.REACT_APP_CLIENT_URL!,
};
