import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { CustomerService, CustomerServiceType } from '../api-clients/WorkshopLocatorApi';
import { CountryCode } from '../enums/country-code';
import { RootState } from '../store/reducers';
import WorkshopLetterEmailCopyIcon from './WorkshopLetterEmailCopyIcon';
import PhotoInspectionSender from './PhotoInspectionSender';
import { workshopLetterBodies, workshopLetterSubjects } from '../constants/workshopLetters';
import ElementTooltip from './ElementTooltip';
import IdsIcon from './IdsIcon';

const CustomerServiceItem = ({ customerService, workshopId }: InputProps): ReactElement | null => {
  const { t } = useTranslation();

  const { countryCode } = useSelector((state: RootState) => state.queryLocation);
  const workshopsByServiceTypes = useSelector((state: RootState) => state.workshopsByServiceType);
  const serviceTabIndex = useSelector((state: RootState) => state.serviceTypeSelectedIndex);
  if (!workshopsByServiceTypes?.length) {
    return null;
  }
  const currentTab = workshopsByServiceTypes[serviceTabIndex]!.workshopServiceType!;

  if (!customerService?.serviceType) {
    return null;
  }

  switch (customerService.serviceType) {
    case CustomerServiceType.PhotoInspection:
      if (currentTab !== 'Body' && currentTab !== 'VintageVehicle') return null;
      return <PhotoInspectionSender workshopId={workshopId} />;
    case CustomerServiceType.GlassPhotoInspection:
      if (currentTab !== 'Glass') return null;
      return (
        <ElementTooltip text={t('customerService.glassPhotoInspection')}>
          <IdsIcon group='product' icon='smartphone' />
        </ElementTooltip>
      );
    case CustomerServiceType.PreInspection:
      // Show no icon for now. In the future, probably, cab booking will be linked just as for PhotoInspection above
      // https://dev.azure.com/if-it/common-remedy-tribe/_workitems/edit/866967
      // return <IconPopover text={t('customerService.preInspection')} icon='calendar' group='ui' />;
      return null;
    case CustomerServiceType.RentalCarCentral:
    case CustomerServiceType.RentalCarLocal:
    case CustomerServiceType.RentalCarOwner:
      return (
        <ElementTooltip text={`${t('customerService.carRental')} ${customerService.rentalCarBrand}`}>
          <IdsIcon group='coverage' icon='rental-car' />
        </ElementTooltip>
      );
    case CustomerServiceType.WorkshopLetter:
      if (countryCode === CountryCode.Norway) {
        return (
          <WorkshopLetterEmailCopyIcon email={customerService?.email} popoverTitle={t('customerService.copyEmail')} />
        );
      }

      const subject = workshopLetterSubjects[countryCode] || 'Workshop Letter';
      const body = workshopLetterBodies[countryCode] || '';

      return (
        <ElementTooltip text={customerService?.email ? t('customerService.sendWorkshopLetter') : ''}>
          <a href={`mailto:${customerService?.email}?subject=${subject}&body=${body}`} className='workshop-letter-item'>
            <IdsIcon group='ui' icon='email' color='blue' />
          </a>
        </ElementTooltip>
      );
    default:
      return null;
  }
};

export default CustomerServiceItem;

type InputProps = {
  customerService: CustomerService | undefined;
  workshopId: string;
};
