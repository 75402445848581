export interface LatLng {
  lat: number;
  lng: number;
}

export class MapMarkerPosition {
  marker: google.maps.Marker;
  latLng: LatLng;
  workshopCode: string;

  constructor(marker: google.maps.Marker, latLng: LatLng, workshopCode: string) {
    this.marker = marker;
    this.latLng = latLng;
    this.workshopCode = workshopCode;
  }
}
