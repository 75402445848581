import React from 'react';
import ElementTooltip from './ElementTooltip';

type InputProps = {
  src: string;
  flagRef: React.RefObject<HTMLImageElement> | null;
  alt: string;
  title: string;
  onClick: () => void;
};

const FlagIcon = ({ src, flagRef, alt, title, onClick }: InputProps): JSX.Element => {
  return (
    <ElementTooltip text={title}>
      <img className='flag-icon' src={src} alt={alt} ref={flagRef} onClick={onClick} />
    </ElementTooltip>
  );
};

FlagIcon.propTypes = {};

export default FlagIcon;
