import React from 'react';
import { Icon, IdsIconSpriteDefinition } from '@ids/react-icon';

type InputProps = Omit<IdsIconSpriteDefinition, 'size'> & {
  color?: 'blue' | 'brown';
};

const IdsIcon = ({ icon, group, color }: InputProps): JSX.Element => {
  color ? `var(--ids-color-${color}-100)` : undefined;

  return (
    <Icon {...({ group, size: 32, icon } as IdsIconSpriteDefinition)} style={{ color: color }} className='if size-16' />
  );
};

export default IdsIcon;
