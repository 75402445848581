import React from 'react';
import { useTranslation } from 'react-i18next';
import { Workshop } from '../api-clients/WorkshopLocatorApi';
import { environment } from '../util/environment';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ElementTooltip from './ElementTooltip';
import IdsIcon from './IdsIcon';

export const WorkshopLink = ({ workshop }: InputProps): JSX.Element | null => {
  const { t } = useTranslation();

  const flags = useFlags();

  if (!workshop || !flags['285610-show-workshop-link']) {
    return null;
  }
  return (
    <ElementTooltip text={t('workshopInfo')}>
      <a href={environment.wsUiUrl + '/' + workshop.id} target='_blank' rel='noreferrer'>
        <IdsIcon group='ui' icon='open-in-new' color='blue' />
      </a>
    </ElementTooltip>
  );
};

type InputProps = {
  workshop: Workshop;
};
