import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const UpdateMe = (): JSX.Element => {
  const { t } = useTranslation();
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    if (!Number(process.env.REACT_APP_BUILD)) {
      return;
    }

    setInterval(() => {
      fetch(window.location.origin + '/version.u', {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'text/html',
        },
      })
        .then((response) => response.text())
        .then((data) => {
          if (Number(data) && Number(data) > Number(process.env.REACT_APP_BUILD)) {
            setUpdateAvailable(true);
          }
        });
    }, 30000);
  }, []);

  return (
    <>
      {updateAvailable && (
        <div className='if notification warning'>
          <span className='if heading smallest'>{t('updateMe.title')}</span>
          <p>{t('updateMe.information')}</p>
          <a href='#' className='if standalone' onClick={(): void => window.location.reload()}>
            {t('updateMe.reloadButton')}
          </a>
        </div>
      )}
    </>
  );
};

export default UpdateMe;
