import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { WorkshopsByServiceType } from '../api-clients/WorkshopLocatorApi';
import { setWorkshops } from '../store/reducers/workshops';
import WorkshopResultTab from './WorkshopResultTab';
import { setServiceTypeSelectedIndex } from '../store/reducers/servicetype-selected-index';
import './WorkshopResultTabList.scss';

type InputProps = {
  workshopsByServiceTypes: (WorkshopsByServiceType | undefined)[];
};

export const WorkshopResultTabList = ({ workshopsByServiceTypes }: InputProps): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);

  const dispatch = useDispatch();

  const tabListRef = useRef<HTMLUListElement>(null);

  function selectServiceType(index: number) {
    setSelectedIndex(index);
    dispatch(setServiceTypeSelectedIndex(index));
  }

  useEffect(() => {
    if (workshopsByServiceTypes[selectedIndex]) {
      dispatch(setWorkshops(workshopsByServiceTypes[selectedIndex]?.workshops ?? []));
    } else {
      dispatch(setWorkshops([]));
      setSelectedIndex(0);
    }
  }, [selectedIndex, workshopsByServiceTypes]);

  function scrollButtonClick(right: boolean) {
    if (!tabListRef.current) return;

    setShowNextButton(!right);
    setShowPrevButton(right);

    tabListRef.current.scrollTo({
      left: right ? tabListRef.current.scrollWidth : 0,
      behavior: 'smooth',
    });
  }

  const workshopResultTabs = workshopsByServiceTypes.map((workshopsByServiceType, index) => (
    <WorkshopResultTab
      key={index}
      index={index}
      selectedIndex={selectedIndex}
      handleTabClick={selectServiceType}
      workshopServiceType={workshopsByServiceType?.workshopServiceType}
    />
  ));

  const showButtons = workshopResultTabs.length > 4;

  return (
    <div className='ws-tab-container'>
      <div className='if tabs size-h-48'>
        {showButtons && showPrevButton && (
          <button className='if prev tab-navigation-button' onClick={() => scrollButtonClick(false)} />
        )}
        <ul className='if ws-service-type-tabs' role='tablist' ref={tabListRef}>
          {workshopResultTabs}
        </ul>
        {showButtons && showNextButton && (
          <button className='if next tab-navigation-button' onClick={() => scrollButtonClick(true)} />
        )}
      </div>
    </div>
  );
};

export default WorkshopResultTabList;
