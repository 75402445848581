import { Form, FormikProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClaimType, InsuranceType, VehicleType } from '../api-clients/WorkshopLocatorApi';
import Query from '../models/Query';
import { RootState } from '../store/reducers';
import { LocationSource, setFromPlaceResult } from '../store/reducers/query-location';
import { setWorkshopsByServiceType } from '../store/reducers/workshops-by-servicetype';
import CountrySpecificDataProvider from '../util/CountrySpecificDataProvider';
import { findAddressFromUrl } from '../util/placesService';
import { resetQueryString, setQueryStringLatLong, setQueryStringValue } from '../util/queryString';
import { ButtonLoader } from './Form/ButtonLoader';
import { LocationInputField } from './Form/LocationInputField';
import { OptionInputFieldFormik } from './Form/OptionInputField';
import LanguageSelector from './LanguageSelector';
import { RegistrationNumberOrManufacturerField } from './Form/RegistrationNumberOrManufacturerField';
import './WorkshopSearchForm.scss';
import ElementTooltip from './ElementTooltip';

type InputProps = {
  children: JSX.Element | undefined;
  loading: boolean;
  formState: FormikProps<Query>;
  countrySpecificDataProvider: CountrySpecificDataProvider;
};

const WorkshopSearchForm = ({ children, loading, formState, countrySpecificDataProvider }: InputProps): JSX.Element => {
  const { t } = useTranslation();
  const workshops = useSelector((state: RootState) => state.workshops);

  const dispatch = useDispatch();
  const { latLng, showMore, queryAddress, countryCode } = useSelector((state: RootState) => state.queryLocation);

  const [showParameters, setShowParameters] = useState(true);

  const resetForm = (): void => {
    resetQueryString(countryCode);
    setShowParameters(true);
    dispatch(
      setFromPlaceResult({
        latLng: null,
        source: LocationSource.None,
        address: '',
        countryCode,
        showMore: false,
      })
    );
    formState.resetForm({
      values: {
        ...formState.values,
        registrationNumber: '',
        latitude: '',
        longitude: '',
        showMore: false,
        countryCode,
        claimType: ClaimType.Collision,
        vehicleType: VehicleType.Car,
        insuranceType: InsuranceType.NonBranded,
      },
    });
  };

  const isValidQuery = (query: Query): boolean => {
    const regNotRequired = query.vehicleType === VehicleType.Moped || query.vehicleType === VehicleType.Trailer;
    return !(query.latitude === '') && !(query.longitude === '') && (regNotRequired || query.registrationNumber !== '');
  };

  useEffect(() => {
    if (latLng && isValidQuery(formState.values)) {
      setShowParameters(false);
      formState.submitForm();
    }
  }, [latLng]);

  const setUrlQueryParameter = (inputField: { target: HTMLInputElement }): void => {
    setQueryStringValue(inputField.target.name, inputField.target.value);
  };

  const updateLocationFromUrlAddress = async (
    results: google.maps.places.PlaceResult[],
    status: google.maps.places.PlacesServiceStatus
  ): Promise<void> => {
    formState.validateForm();
    if (status !== google.maps.places.PlacesServiceStatus.OK || !results || !results[0]) {
      return;
    }
    const placeResult = results[0];
    const lat = placeResult.geometry?.location.lat();
    const lng = placeResult.geometry?.location.lng();

    if (!lat || !lng) {
      return;
    }
    const address = placeResult.formatted_address || '';
    setQueryStringValue('address', address);
    dispatch(
      setFromPlaceResult({
        latLng: { lat: lat, lng: lng },
        source: LocationSource.Url,
        address: address,
        showMore,
      })
    );
    //fill form
    formState.setValues({
      ...formState.values,
      latitude: lat,
      longitude: lng,
    });

    formState.submitForm();
  };

  //so this one should catch url address, get latlng and then update store again
  useEffect(() => {
    if (queryAddress && !latLng) {
      findAddressFromUrl(queryAddress, updateLocationFromUrlAddress);
    }
  }, [queryAddress, latLng]);

  //sets formState lat/lang values when location changes
  useEffect(() => {
    if (latLng && formState?.values) {
      setQueryStringLatLong(latLng);
      formState.setValues({
        ...formState.values,
        latitude: latLng?.lat || '',
        longitude: latLng?.lng || '',
        showMore,
      });
      dispatch(setWorkshopsByServiceType([]));
    }
  }, [latLng, showMore]);

  const expandableStateClass = showParameters ? 'is-open' : 'is-closed';

  const toggleExpandable = (): void => {
    setShowParameters(!showParameters);
  };

  const claimTypes = countrySpecificDataProvider.claimTypes;
  const insuranceTypes = countrySpecificDataProvider.insuranceTypes;
  const vehicleTypes = countrySpecificDataProvider.vehicleTypes;

  return (
    <Form className='if ws-form'>
      <LanguageSelector meta={formState} />
      <LocationInputField meta={formState} />
      <div className={`if ${expandableStateClass}`} id='search-parameters-panel'>
        <div className='if title' data-cy='expandable' onClick={(): void => toggleExpandable()}>
          {t('vehicleInformation.title')}
        </div>
        {showParameters && (
          <div className='if content' role='region'>
            <div className='if form-row'>
              <div className='if form-field'>
                <RegistrationNumberOrManufacturerField setUrlQueryParameter={setUrlQueryParameter} />
              </div>
              <div className='if form-field'>
                <OptionInputFieldFormik
                  label={t('vehicleInformation.vehicleType')}
                  name='vehicleType'
                  as='select'
                  onBlur={setUrlQueryParameter}
                >
                  {vehicleTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {t(type.text)}
                    </option>
                  ))}
                </OptionInputFieldFormik>
              </div>
            </div>

            <div className='if form-row'>
              <div className='if form-field'>
                <OptionInputFieldFormik
                  label={t('vehicleInformation.claimType')}
                  name='claimType'
                  as='select'
                  onBlur={setUrlQueryParameter}
                >
                  {claimTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {t(type.text)}
                    </option>
                  ))}
                </OptionInputFieldFormik>
              </div>
              <div className='if form-field'>
                <OptionInputFieldFormik
                  label={t('vehicleInformation.insuranceType')}
                  name='insuranceType'
                  as='select'
                  onBlur={setUrlQueryParameter}
                >
                  {insuranceTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {t(type.text)}
                    </option>
                  ))}
                </OptionInputFieldFormik>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='if form-actions'>
        <ButtonLoader disabled={formState.isSubmitting || loading || !formState.isValid} loading={loading}>
          {t('search')}
        </ButtonLoader>

        <div className='workshop-refresh-wrapper'>
          {workshops.length > 0 && (
            <ElementTooltip text={t('resetFormStateTitle')}>
              <button type='reset' onClick={resetForm} className='if icon symbol loop workshop-refresh' />
            </ElementTooltip>
          )}
        </div>
      </div>
      {children}
    </Form>
  );
};

export default WorkshopSearchForm;
