import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import MapService from '../util/mapService';

const WorkshopMap = ({ setMap }: InputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { countryCode } = useSelector((state: RootState) => state.queryLocation);

  //initiate map
  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    const map = MapService.getMap(inputRef.current, countryCode);
    setMap(map);
  }, [setMap]);

  return <div ref={inputRef} id='workshopsMap' className='ws-map' />;
};

type InputProps = {
  setMap: (map: google.maps.Map) => void;
};

export default WorkshopMap;
