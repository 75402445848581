import { useEffect } from 'react';

const useEventListenerEffect = (eventType: string, handleEvent: (event: Event) => void): void => {
  useEffect(() => {
    document.addEventListener(eventType, handleEvent);
    return (): void => {
      document.removeEventListener(eventType, handleEvent);
    };
  }, []);
};

export default useEventListenerEffect;
