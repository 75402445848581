import { CountryCode } from '../enums/country-code';

export const countries = [
  {
    code: CountryCode.Sweden,
    name: 'countries.sweden',
  },
  {
    code: CountryCode.Finland,
    name: 'countries.finland',
  },
  {
    code: CountryCode.Norway,
    name: 'countries.norway',
  },
];
