import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkshopsByServiceType } from '../../api-clients/WorkshopLocatorApi';

const workshopsByServiceTypeSlice = createSlice({
  name: 'workshopsByServiceType',
  initialState: [] as (WorkshopsByServiceType | undefined)[],
  reducers: {
    setWorkshopsByServiceType(
      state,
      action: PayloadAction<(WorkshopsByServiceType | undefined)[]>
    ): (WorkshopsByServiceType | undefined)[] {
      return action.payload;
    },
  },
});

export const { setWorkshopsByServiceType } = workshopsByServiceTypeSlice.actions;

export default workshopsByServiceTypeSlice.reducer;
