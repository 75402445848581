import { FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Query from '../models/Query';
import { RootState } from '../store/reducers';
import { LocationSource, setFromPlaceResult } from '../store/reducers/query-location';
import { instantiateAutocomplete } from '../util/placesService';
import { setQueryStringValue } from '../util/queryString';
import { useTranslation } from 'react-i18next';
import './LocationFinder.scss';

type InputProps = {
  inputClasses: string;
  meta: FormikProps<Query>;
};

export const LocationFinder = ({ inputClasses }: InputProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { locationSource, queryAddress } = useSelector((state: RootState) => state.queryLocation);
  const [addressValue, setAddressValue] = useState(queryAddress);
  const [placeHolder, setPlaceHolder] = useState('locationFinder.enterLocation');

  // reference to input used for autocomplete
  const inputRef = useRef<HTMLInputElement>(null);

  //reference to Google Autocomplete Widget
  const autocompleteRef = useRef<google.maps.places.Autocomplete>({} as google.maps.places.Autocomplete);

  // set selected location from autocomplete result
  function autocompletePlaceChanged(): void {
    const selectedAddress = autocompleteRef.current.getPlace();
    const address = selectedAddress.formatted_address || '';
    if (address) {
      setQueryStringValue('address', address);
      setQueryStringValue('showMore', 'false');
    }
    if (selectedAddress && selectedAddress.geometry) {
      dispatch(
        setFromPlaceResult({
          latLng: {
            lat: selectedAddress.geometry?.location.lat(),
            lng: selectedAddress.geometry?.location.lng(),
          },
          source: LocationSource.InputField,
          address: selectedAddress.formatted_address || '',
          showMore: false,
        })
      );
    }
  }

  const setPlaceholderText = (locationSource: LocationSource): void => {
    if (!inputRef.current) {
      return;
    }

    switch (locationSource) {
      case LocationSource.Map:
        inputRef.current.value = '';

        setPlaceHolder('locationFinder.usingMap');
        break;
      case LocationSource.Url:
        setPlaceHolder('locationFinder.usingUrl');
        break;
      default:
        setPlaceHolder('locationFinder.enterLocation');
        break;
    }
  };

  // instantiate autocomplete field
  useEffect(() => {
    if (!inputRef.current) {
      return;
    }
    autocompleteRef.current = instantiateAutocomplete(inputRef.current, autocompletePlaceChanged);
  }, [inputRef]);

  useEffect(() => {
    if (inputRef.current && (locationSource === LocationSource.Map || locationSource === LocationSource.Url)) {
      setPlaceholderText(locationSource);
    }
  }, [locationSource]);

  useEffect(() => {
    setAddressValue(queryAddress);
  }, [queryAddress]);

  return (
    <>
      <label className='if mb-4' htmlFor='searchTextField'>
        {t('locationFinder.address')}
      </label>
      <input
        className={`if input-field ${inputClasses}`}
        ref={inputRef}
        name='NotAddress' //should maybe be random to prevent Chrome autofill
        id='searchTextField'
        type='search'
        value={addressValue}
        placeholder={t(placeHolder)}
        onChange={(event): void => {
          setAddressValue(event.target.value);
        }}
        onFocus={(): void => {
          setPlaceholderText(LocationSource.InputField);
        }}
        onBlur={(): void => {
          setPlaceholderText(locationSource);
        }}
      />
    </>
  );
};
