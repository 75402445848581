import { FormikProps } from 'formik';
import React, { createRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { countries } from '../constants/countries';
import { countryCodeLanguages } from '../constants/languages';
import { CountryCode } from '../enums/country-code';
import useClickOutsideEffect from '../hooks/useClickOutsideEffect';
import useEventListenerEffect from '../hooks/useEventListenerEffect';
import i18n from '../i18n';
import Query from '../models/Query';
import { setCountryCode } from '../store/reducers/query-location';
import { setQueryStringValue } from '../util/queryString';
import FlagIcon from './FlagIcon';
import './CountryToggler.scss';

type InputProps = {
  meta: FormikProps<Query>;
};

const CountryToggler = ({ meta }: InputProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [flagMenuVisible, setFlagMenuVisible] = useState(false);
  const [menuLeft, setMenuLeft] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);
  const flagRef = createRef<HTMLImageElement>();
  const countryCode = meta.values.countryCode.toUpperCase();

  const handleClickOutside = (event: MouseEvent): void => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setFlagMenuVisible(false);
    }
  };

  useClickOutsideEffect(handleClickOutside);
  useEventListenerEffect('keydown', (): void => setFlagMenuVisible(false));

  const toggleFlagMenu = (): void => {
    if (flagRef.current) {
      setMenuLeft(flagRef.current.offsetLeft - flagRef.current.clientWidth / 2);
    }

    setFlagMenuVisible(!flagMenuVisible);
  };

  const handleCountryChange = (countryCode: CountryCode): void => {
    setQueryStringValue('countryCode', countryCode);
    meta.setValues(
      {
        ...meta.values,
        countryCode: countryCode,
      },
      false
    );
    dispatch(setCountryCode(countryCode));
    setFlagMenuVisible(false);
  };

  useEffect(() => {
    i18n.changeLanguage(countryCodeLanguages[countryCode]);
  }, [countryCode]);

  const countryOptions = countries
    .filter((c) => c.code !== countryCode)
    .map((c) => (
      <li className='if' key={c.code}>
        <FlagIcon
          src={`images/flags/${c.code}.svg`}
          alt={t(c.name)}
          title={t('changeCountryTo') + ' ' + t(c.name)}
          flagRef={null}
          onClick={(): void => handleCountryChange(c.code)}
        />
      </li>
    ));

  return (
    <div className='country-toggler'>
      <FlagIcon
        src={`images/flags/${countryCode}.svg`}
        alt={countryCode}
        title={t('changeCountry')}
        flagRef={flagRef}
        onClick={toggleFlagMenu}
      />
      {flagMenuVisible && (
        <div role='menu' className='if menu pinned top is-open' style={{ left: menuLeft }} ref={menuRef}>
          <ul className='if'>{countryOptions}</ul>
        </div>
      )}
    </div>
  );
};

export default CountryToggler;
