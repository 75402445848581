import React, { ChangeEvent, useState } from 'react';
import './PhotoInspectionSender.scss';
import { Icon } from '@ids/react-icon';
import { useTranslation } from 'react-i18next';
import { DialogModal } from './DialogModal';
import { useSelector } from 'react-redux';
import { RootState } from '../store/reducers';
import { E164Number, InputField, InputType, PhoneNumberInputField } from '@ids-react/input-fields';
import { InputConstraint } from '@ids-react/common';
import { CountryCode } from '../enums/country-code';
import Toast, { ToastType } from './Toast';
import { Brand, CreateInspectionRequest, InspectionType, PhotoSource } from '../api-clients/PhotoInspectionApiClient';
import PhotoInspectionService from '../util/PhotoInspectionService';
import UrlService from '../util/UrlService';
import { getAccount } from '../util/auth';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DropdownSelect } from '@ids/react-dropdown-select';
import ElementTooltip from './ElementTooltip';
import IdsIcon from './IdsIcon';

function getInitialFormData() {
  return {
    phoneNumber: '',
    brand: undefined as Brand | undefined,
    claimNumber: '',
  };
}
type Inputs = ReturnType<typeof getInitialFormData>;

const EnabledBrands: Record<string, Brand[]> = {
  SE: [
    Brand.If,
    Brand.Volvia,
    Brand.Renault,
    Brand.Volkswagen,
    Brand.NotBranded,
    Brand.Audi,
    Brand.Kia,
    Brand.Mercedes,
    Brand.Skoda,
    Brand.Nissan,
    Brand.Ford,
  ],
  FI: [Brand.If, Brand.Volvia, Brand.NotBranded],
  NO: [Brand.If, Brand.Volvia],
  DK: [Brand.If],
};

export default function PhotoInspectionSender({ workshopId }: { workshopId: string }) {
  const urlService = new UrlService();
  const user = getAccount();
  const flags = useFlags();

  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [toast, setToast] = useState<{ type: ToastType; message: string } | null>(null);
  const [inputs, setInputs] = useState(getInitialFormData);
  const [sending, setSending] = useState(false);

  function reset() {
    setModalOpen(false);
    setInputs(getInitialFormData);
    setSending(false);
  }

  function onInputChange(key: keyof Inputs, value: string) {
    setInputs((state) => ({ ...state, [key]: value }));
  }

  async function sendSms() {
    setSending(true);
    try {
      const request: CreateInspectionRequest = {
        workshopId,
        sendSms: true,
        createdBy: user.name,
        createdBySystem: 'workshop-locator-ui',
        type: InspectionType.Body,
        brand: inputs.brand!,
        photoSource: PhotoSource.Customer,
        customer: {
          registrationNumber: urlService.getRegistrationNumber(),
          phoneNumber: inputs.phoneNumber,
        },
        claimNumber: inputs.claimNumber ? inputs.claimNumber : undefined,
      };

      await PhotoInspectionService.newInspection(request);

      setToast({ message: t('inspectionModal.success'), type: ToastType.Success });
      reset();
    } catch (error) {
      console.error({ error });
      setToast({ message: t('inspectionModal.failure'), type: ToastType.Error });
      setSending(false);
    }
  }

  if (!flags['723943-send-photo-inspection-sms']) {
    return (
      <ElementTooltip text={t('customerService.photoInspection')}>
        <IdsIcon group='ui' icon='camera' color='blue' />
      </ElementTooltip>
    );
  }

  return (
    <>
      <ElementTooltip text={t('customerService.photoInspection')}>
        <span onClick={() => setModalOpen(true)} style={{ cursor: 'pointer' }}>
          <IdsIcon group='ui' icon='camera' color='blue' />
        </span>
      </ElementTooltip>

      {toast && <Toast {...toast} duration={5000} onClose={() => setToast(null)} />}

      {modalOpen && (
        <DialogModal
          header={
            <div className='if flex-center justify-content-space-between'>
              <h2>{t('inspectionModal.title')}</h2>
              <button onClick={reset} type='button' className='if button text'>
                <Icon group='ui' size={24} icon='cross' style={{ color: 'var(--ids-color-brown-100)' }} />
              </button>
            </div>
          }
          content={<PhotoInspectionForm inputs={inputs} onInputChange={onInputChange} />}
          actions={
            <div className='if flex-row gap-12'>
              <button
                onClick={sendSms}
                disabled={!inputs.phoneNumber || !inputs.brand || sending}
                type='button'
                className='if button filled'
              >
                {t('inspectionModal.SendSmsButton')}
              </button>
              <button onClick={reset} type='button' className='if button outlined'>
                {t('inspectionModal.CancelButton')}
              </button>
            </div>
          }
        />
      )}
    </>
  );
}

const PhotoInspectionForm = ({
  inputs,
  onInputChange,
}: {
  inputs: Inputs;
  onInputChange: (key: keyof Inputs, value: string) => void;
}) => {
  const { t } = useTranslation();
  const { countryCode } = useSelector((state: RootState) => state.queryLocation);

  function onClaimNumberChange(event: ChangeEvent<HTMLInputElement>) {
    onInputChange('claimNumber', event.target.value);
  }

  function onBrandChange(event: ChangeEvent<HTMLSelectElement>) {
    onInputChange('brand', Brand[event.target.value as Brand]);
  }

  function onPhoneChange(value: E164Number) {
    onInputChange('phoneNumber', value);
  }

  return (
    <div className='if flex-column gap-32'>
      <div className='if'>{t('inspectionModal.subtitle')}</div>
      <PhoneNumberInputField
        onValueChange={onPhoneChange}
        constraint={InputConstraint.REQUIRED}
        label={t('inspectionModal.phoneNumber')}
        value={inputs.phoneNumber}
        id='phoneNumber'
        name='phoneNumber'
        countryListAriaLabel=''
        defaultCountry={countryCode as CountryCode}
        preferredCountries={['FI', 'SE', 'NO', 'DK']}
        inputWrapperClassName='phone-input'
      />

      <div className='if flex-column gap-8'>
        <label className='if label is-required' htmlFor='insurance-type'>
          {t('inspectionModal.insuranceType')}
        </label>
        <DropdownSelect id='insurance-type' onChange={onBrandChange} value={inputs.brand?.toString() ?? 'initial'}>
          <option value='initial' disabled>
            {t('inspectionModal.selectInsurance')}
          </option>
          {EnabledBrands[countryCode].map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        </DropdownSelect>
      </div>
      <div className='if flex-column gap-8'>
        <label className='if label is-optional' htmlFor='claim-number'>
          {t('inspectionModal.claimNumberLabel')}
        </label>
        <InputField
          id='claim-number'
          name='claim-number'
          type={InputType.TEXT}
          placeholder='UFO01A/19/190817'
          value={inputs.claimNumber}
          onChange={onClaimNumberChange}
        />
      </div>
    </div>
  );
};
