import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const serviceTypeSelectedIndexSlice = createSlice({
  name: 'workshopsByServiceType',
  initialState: 0,
  reducers: {
    setServiceTypeSelectedIndex(state, action: PayloadAction<number>): number {
      return (state = action.payload);
    },
  },
});

export const { setServiceTypeSelectedIndex } = serviceTypeSelectedIndexSlice.actions;

export default serviceTypeSelectedIndexSlice.reducer;
