import { LocatorApiClient, AuthClient, WorkshopsByServiceType } from '../api-clients/WorkshopLocatorApi';
import Query from '../models/Query';
import { getApiToken } from './auth';
import { environment } from './environment';

const workshopsLocatorService = {
  async search(query: Query): Promise<(WorkshopsByServiceType | undefined)[]> {
    const token = await getApiToken();
    const authClient = new AuthClient(token);

    let registrationNumber: string | undefined = query.registrationNumber;
    let internationalManufacturerCode: string | undefined = undefined;
    if (query.registrationNumber?.startsWith('#')) {
      registrationNumber = undefined;
      internationalManufacturerCode = query.registrationNumber.substr(1);
    }

    const client = new LocatorApiClient(authClient, environment.wslApiUrl);
    return client.search(
      query.vehicleType,
      query.claimType,
      query.countryCode,
      registrationNumber,
      internationalManufacturerCode,
      query.insuranceType,
      undefined,
      undefined,
      undefined,
      query.latitude || undefined,
      query.longitude || undefined,
      query.showMore || undefined,
      'workshop-locator-ui',
      undefined
    );
  },
};

export default workshopsLocatorService;
