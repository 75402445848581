import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import {
  ClaimType,
  ValidationProblemDetails,
  VehicleType,
  WorkshopsByServiceType,
} from '../api-clients/WorkshopLocatorApi';
import Query from '../models/Query';
import { LocationSource, setCountryCode, setFromPlaceResult } from '../store/reducers/query-location';
import { setWorkshops } from '../store/reducers/workshops';
import { setWorkshopsByServiceType } from '../store/reducers/workshops-by-servicetype';
import CountrySpecificDataProvider from '../util/CountrySpecificDataProvider';
import UrlService from '../util/UrlService';
import workshopsLocatorService from '../util/WorkshopsLocatorService';
import ErrorDisplay from './ErrorDisplay';
import WorkshopSearchForm from './WorkshopSearchForm';

const WorkshopSearchFormik = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [query, setQuery] = useState<Query | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [queryError, setQueryError] = useState<ValidationProblemDetails | undefined>(undefined);

  // Run once on start
  // Set from URL-values
  useEffect(() => {
    const urlService = new UrlService();
    const latLng = urlService.getLatLng();
    const address = urlService.getAddress();
    const query = new Query(urlService);
    setQuery(query);

    if (latLng) {
      dispatch(
        setFromPlaceResult({
          latLng,
          source: LocationSource.Url,
          address,
          countryCode: query.countryCode,
          showMore: query.showMore ?? false,
        })
      );
    } else if (address) {
      dispatch(
        setFromPlaceResult({
          latLng: null,
          source: LocationSource.Url,
          address,
          countryCode: query.countryCode,
          showMore: query.showMore ?? false,
        })
      );
    } else {
      dispatch(setCountryCode(query.countryCode));
    }
  }, []);

  const responseHandler = async (promise: Promise<(WorkshopsByServiceType | undefined)[]>): Promise<void> => {
    const data = await promise
      .then<(WorkshopsByServiceType | undefined)[]>((data) => data)
      .catch((err) => {
        setQueryError(err);
      })
      .finally(() => {
        setLoading(false);
      });
    if (data) {
      dispatch(setWorkshopsByServiceType(data));
    }
  };

  // using Formik and Yup to configure the search form
  return (
    <>
      {!!query && (
        <Formik
          initialValues={query}
          validationSchema={Yup.object({
            registrationNumber: Yup.string()
              //.matches(/(\N)/,'Registration number should be XXXNNN or XXXNNN')
              .max(7, t('formWarnings.registrationNumberLength'))
              .min(2, t('formWarnings.registrationNumberLength'))
              .when('vehicleType', {
                is: (value) => !['Moped', 'Trailer'].includes(value),
                then: Yup.string().required(t('formWarnings.registrationNumberRequired')),
              }),
            vehicleType: Yup.string().oneOf(Object.values(VehicleType)),
            claimType: Yup.string().oneOf(Object.values(ClaimType)),
            countryCode: Yup.string(),
            latitude: Yup.number().required(t('formWarnings.missingLocation')),
            longitude: Yup.number().required(t('formWarnings.missingLocation')),
          })}
          onReset={(): void => {
            dispatch(setWorkshops([]));
            dispatch(setWorkshopsByServiceType([]));
          }}
          onSubmit={async (values, { setSubmitting }): Promise<void> => {
            setSubmitting(false);
            setQueryError(undefined);
            dispatch(setWorkshops([]));
            dispatch(setWorkshopsByServiceType([]));
            setLoading(true);
            responseHandler(workshopsLocatorService.search(values));
          }}
        >
          {(formState): JSX.Element => (
            <WorkshopSearchForm
              loading={loading}
              formState={formState}
              countrySpecificDataProvider={new CountrySpecificDataProvider(formState.values.countryCode)}
            >
              {queryError && <ErrorDisplay error={queryError} />}
            </WorkshopSearchForm>
          )}
        </Formik>
      )}
    </>
  );
};

export default WorkshopSearchFormik;
