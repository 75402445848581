export const languages = [
  {
    code: 'en',
    name: 'language.english',
  },
  {
    code: 'fi',
    name: 'language.finnish',
  },
  {
    code: 'no',
    name: 'language.norwegian',
  },
  {
    code: 'sv',
    name: 'language.swedish',
  },
];

export const countryCodeLanguages: { [key: string]: string } = {
  SE: 'sv',
  FI: 'fi',
  NO: 'no',
};
