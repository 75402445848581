import React from 'react';

type InputProps = {
  duration?: number;
};

const Duration = ({ duration }: InputProps): JSX.Element => {
  if (!duration) {
    return <></>;
  }

  const durationStringMinutes = (durationMinutes: number): string =>
    `${durationMinutes.toLocaleString('sv-SE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })} min`;

  const durationStringHoursMinutes = (durationHours: number, durationMinutes: number): string => {
    const minutesRemainder = (durationMinutes % 60).toLocaleString('sv-SE', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

    return `${Math.floor(durationHours)}h ${minutesRemainder} min`;
  };

  const durationMinutes = duration / 60;
  const durationHours = durationMinutes / 60;

  return (
    <span className='if typography ui medium weight-bold'>
      {durationHours >= 1
        ? durationStringHoursMinutes(durationHours, durationMinutes)
        : durationStringMinutes(durationMinutes)}
    </span>
  );
};

export default Duration;
