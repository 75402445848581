import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatLng } from '../../models/LatLng';

export enum LocationSource {
  None = 'None',
  Map = 'Map',
  Url = 'Url',
  InputField = 'InputField',
}
type QueryLocation = {
  latLng: LatLng | null;
  locationSource: LocationSource;
  queryAddress: string;
  countryCode: string;
  showMore: boolean;
};

const initialState: QueryLocation = {
  latLng: null,
  locationSource: LocationSource.None,
  queryAddress: '',
  countryCode: '',
  showMore: false,
};

const queryLocationSlice = createSlice({
  name: 'queryLocation',
  initialState: initialState as QueryLocation,
  reducers: {
    setQueryLocation(state, action: PayloadAction<LatLng>): QueryLocation {
      return { ...state, latLng: action.payload };
    },
    setLocationSource(state, action: PayloadAction<LocationSource>): QueryLocation {
      return { ...state, locationSource: action.payload };
    },
    setCountryCode(state, action: PayloadAction<string>): QueryLocation {
      return { ...state, countryCode: action.payload.toUpperCase() };
    },
    setQueryAddress(state, action: PayloadAction<string>): QueryLocation {
      return { ...state, queryAddress: action.payload };
    },
    setFromShowWorkshops(state, action: PayloadAction<boolean>): QueryLocation {
      return { ...state, showMore: action.payload };
    },
    setFromPlaceResult(
      state,
      action: PayloadAction<{
        latLng: LatLng | null;
        source: LocationSource;
        address: string;
        countryCode?: string;
        showMore: boolean;
      }>
    ): QueryLocation {
      const newStore: QueryLocation = {
        latLng: action.payload.latLng,
        locationSource: action.payload.source,
        queryAddress: action.payload.address,
        countryCode: action.payload.countryCode?.toUpperCase() || state.countryCode,
        showMore: action.payload.showMore,
      };

      return newStore;
    },
  },
});

export const {
  setQueryLocation,
  setLocationSource,
  setQueryAddress,
  setCountryCode,
  setFromPlaceResult,
  setFromShowWorkshops,
} = queryLocationSlice.actions;

export default queryLocationSlice.reducer;
